let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Sydney Dlhopolsky",
    "role": "Software Engineer",
    "linkedinId":"sydney-dlhopolsky",
    "roleDescription": "Hello! I am a software engineer, artist, animal lover, nerd, snowboarder, rollerblader, pescatarian, and sushi aficionado. ",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/sydney-dlhopolsky/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/SydneyDlh",
          "className":"fa fa-github"
        }
      ],
    "aboutme":"I am a Senior at the University of Rochester studying computer science. I am passionate about emerging technologies and solving complex problems in simple ways. My most recent experience is a Data Platforms Intern at The Walt Disney Company. Through that internship I got to design and create a machine learning project. Previously I had an internship at Red Hat where I wrote programs to automate GitLab processes among other work. I also participate in augmented reality research and am a studio art major! I love cartoons, spending time outdoors, playing games, petting cats, and learning about our deep blue sea. ",
    "address":"sydney.dlhopolsky@gmail.com",
    "website":"https://github.com/SydneyDlh",
    "education":[
      {
        "UniversityName":"University of Rochester",
        "specialization":"Computer Science and Studio Art",
        "MonthOfPassing":"May",
        "YearOfPassing":"2021",
        "Achievements":""
      },
      {
        "UniversityName":"Stuyvesant High School",
        "specialization":"",
        "MonthOfPassing":"June",
        "YearOfPassing":"2017",
        "Achievements":""
      }
    ],
    "work":[
      {
        "CompanyName":"The Walt Disney Company",
        "specialization":"Data Platforms Intern",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2020",
        "Achievements":"During this internship I designed and developed a machine learning project to provide data-driven content analysis. For this model I built a video sentiment analysis tool in Python using transfer learning, object detection, computer vision techniques, and large data sets.",
        "Tools":[
          {
            "toolname": "Jupyter"
          },
          {
            "toolname": "Snowflake"
          },
          {
            "toolname": "AWS"
          },
          {
            "toolname": "TensorFlow"
          }
        ]
      },
      {
        "CompanyName":"Red Hat",
        "specialization":"Software Engineering Intern",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2019",
        "Achievements":"I was able to be involved in many different components of the software lifecycle while working on an agile development team. I started my internship doing front end development using react. After pushing a few features to production I decided to switch over to backend work. I developed a scalable and reliable program using GraphQL, GitLab API, and DevOps principles to automate GitLab processes. I also used threading to speed up multiple python programs making them 60-80% faster. I learned a lot about working on a team and how the software products go from development to production.",
        "Tools":[
          {
            "toolname": "DevOps"
          },
          {
            "toolname": "GraphQL"
          },
          {
            "toolname": "Python"
          },
          {
            "toolname": "React"
          },
          {
            "toolname": "Git"
          }
        ]
      },
      {
        "CompanyName":"Rettner Digital Media Lab",
        "specialization":"Lead Digital Media Specialist",
        "MonthOfLeaving":"Present",
        "YearOfLeaving":"",
        "Achievements":"At Rettner I work to help students use a multitude of digital media technologies from virtual reality to 3D printing. We host events to help students with their careers and hobbies. I was recently promoted to a lead and hope to use this position to increase community involvement and help more people! ",
        "Tools":[
          {
            "toolname": "AR/VR"
          },
          {
            "toolname": "3D Printing"
          },
          {
            "toolname": "Leadership"
          },
          {
            "toolname": "Photoshop"
          }
        ]
      },
      {
        "CompanyName":"ROC HCI",
        "specialization":"Student Researcher",
        "MonthOfLeaving":"June",
        "YearOfLeaving":"2020",
        "Achievements":"I work with Dr.Bai in the ROC HCI lab to research augmented reality. We are developing an optical design application using HoloLens and unity. Through this role I completed literature review, prototyping, system design and development, and professional research writing. I learned a lot through this experience. The most valuable skills I learned were reading scientific writing and iterative design.",
        "Tools":[
          {
            "toolname": "Augmented Reality"
          },
          {
            "toolname": "Unity"
          },
          {
            "toolname": "Human Computer Interaction"
          }
        ]
      }
    ],
    "skillsDescription":"",
    "skills":[
      {
        "skillname":"Python"
      },
      {
        "skillname":"Java"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData