import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

export default class Tools extends Component {
  render() {
    let toolNames = this.props.toolNames;
    return (
      <div className="chipArray">
         {
                toolNames && toolNames.map((item) => {
                  return(
                    <Chip
                      label={item.toolname}
                      variant="outlined" 
                      color="primary"
                      className = "chip"
                    />

                  )
                })
              }
      </div>
        );
  }
}